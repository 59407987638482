import React, { useEffect, useState } from 'react';
import '../static/MainPage.css';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/kaskoresponseview.css';
import '../static/osiguranje.webflow.css';
import axios from 'axios';
import config from '../config';
import Header from './HeaderUser';
import Footer from './FooterUser';
import LoadingSpinner from './LoadingSpinner';

function KaskoResponseView() {
    const [groupedResponses, setGroupedResponses] = useState([]);
    const [selectedResponse, setSelectedResponse] = useState(null);
    const [selectedResponseForPayment, setSelectedResponseForPayment] = useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [responsesForSelectedRequest, setResponsesForSelectedRequest] = useState([]);
    const [noResponsesMessage, setNoResponsesMessage] = useState(false);
    const [showPaymentButton, setShowPaymentButton] = useState(false);
    const [paymentSlip, setPaymentSlip] = useState(null);
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(''); // New state for phone number

    const getAuthToken = () => localStorage.getItem('token');

    const fetchResponses = async () => {
        try {
            const token = getAuthToken();
            if (!token) return console.error('JWT not found in localStorage');

            const response = await fetch(`${config.BASE_URL}api/policyres/getkasko/all`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();
            setGroupedResponses(result);
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    };

    const fetchPdfData = async (responseId) => {
        try {
            const token = getAuthToken();
            setLoading(true);
            if (!token) return console.error('JWT not found in localStorage');

            const response = await axios.get(`${config.BASE_URL}api/policyres/getkasko/getPdf?id=${responseId}`, {
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` },
            });

            const file = window.URL.createObjectURL(response.data);
            setPdfData(file);
            setLoading(false);
            console.log('PDF Data:', file);

        } catch (error) {
            setLoading(false);
            alert("Došlo je do problema, molimo pokušajte ponovo!");
            console.error('Error fetching PDF:', error);
        }
    };

    const fetchResponsesByRequestId = async (requestId) => {
        try {
            const token = getAuthToken();
            setLoading(true);
            if (!token) return console.error('JWT not found in localStorage');

            const response = await axios.get(`${config.BASE_URL}api/policyres/getkasko?requestid=${requestId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setResponsesForSelectedRequest(response.data);
            setNoResponsesMessage(response.data.length === 0);
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Error fetching responses by request ID:', error);
        }
    };

    const handleBuyButtonClick = (response) => {
        console.log(response.id);
        setSelectedResponseForPayment(response.id);
        fetchPdfData(response.id);
        setShowPaymentButton(true);
    };

    const handleGeneratePaymentSlip = async () => {
        try {
            const token = getAuthToken();
            setLoading(true);
            if (!token) return console.error('JWT not found in localStorage');

            const response = await axios.post(`${config.BASE_URL}api/payment/generate`,
                { responseId: selectedResponseForPayment, phoneNumber }, // Include phone number in request
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    responseType: 'blob'
                }
            );

            const file = window.URL.createObjectURL(response.data);
            setPaymentSlip(file);
            setLoading(false);
            alert('Uplatnica uspješno generisana!');
        } catch (error) {
            setLoading(false);
            console.error('Error generating payment slip:', error);
        }
    };

    useEffect(() => {
        if (selectedResponse) fetchResponsesByRequestId(selectedResponse.id);
    }, [selectedResponse]);

    useEffect(() => {
        fetchResponses();
        if (selectedResponse) fetchPdfData(selectedResponse.id);
    }, []);

    const handleResponseChange = (e) => {
        setSelectedResponse(JSON.parse(e.target.value));
        setPdfData(null);           // Clear the PDF data
        setPaymentSlip(null);       // Clear the payment slip
        setPhoneNumber('');         // Clear the phone number
        setShowPaymentButton(false); // Hide the payment button
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    return (
        <div>
            <Header />
            <div className="container">
                <div className="dropdown-container">
                    <select className="dropdown-menu" onChange={handleResponseChange}>
                        <option value={null}>Izaberite automobil za koji želite da vidite ponude</option>
                        {groupedResponses.slice().reverse().map((response) => (
                            <option key={response.id} value={JSON.stringify(response)}>
                                Datum zahtjeva: {formatDate(response.date)},Tip: {response.type === 'CAR' ? 'AUTOMOBIL' : (response.type === 'TRUCK' ? 'TERETNO VOZILO' : 'MOTOCIKL')}, Naziv vozila: {response.brand}, Godište: {response.year}, Kilometraža: {response.mileage}, Kilovati: {response.kilowatts}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedResponse && (
                    <div className="response-container">
                        <h2>Gledate ponude za zahtjev za kasko za {selectedResponse.brand}, {selectedResponse.year} godište</h2>
                        <div className="response-details">
                            <p><strong>Datum ponude:</strong> {formatDate(selectedResponse.date)}</p>
                            <p><strong>Marka:</strong> {selectedResponse.brand}</p>
                            <p><strong>Tip:</strong> {selectedResponse.type === 'CAR' ? 'AUTOMOBIL' : (selectedResponse.type === 'TRUCK' ? 'TERETNO VOZILO' : 'MOTOCIKL')}</p>
                            <p><strong>Stanje:</strong> {selectedResponse.newornot ? 'NOVO' : 'POLOVNO'}</p>
                            <p><strong>Kilometraža:</strong> {selectedResponse.mileage}</p>
                        </div>
                        {noResponsesMessage && <p className="no-response-message">Trenutno nemate odgovora na zahtjevanu polisu, molimo pricekajte, ukoliko vam je hitno pozovite broj telefona +38766888393.</p>}
                        <div className="offers-container">
                            {responsesForSelectedRequest.map((response) => (
                                <div key={response.id} className="offer-box">
                                    <h3><strong>Cijena:</strong> {response.amount} Konvertibilnih maraka</h3>
                                    <p><strong>Broj polise:</strong> {response.policynumber}</p>
                                    <p><strong>Osiguravajuća kuća:</strong> {response.insurancehouse}</p>
                                    <button className="buy-button" onClick={() => handleBuyButtonClick(response)}>Pogledajte detalje polise (PDF fajl) i kupite polisu</button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            {pdfData && (
                <div className="pdf-container">
                    <iframe src={pdfData} className="pdf-iframe" style={{margin:"auto"}}/>
                    <a href={pdfData} download className="pdf-download-link">Preuzmite PDF</a>
                </div>
            )}
            {showPaymentButton && (
                <div className="phone-number-section">
                    <p className="phone-number-text">Ukoliko želite kupiti izabranu polisu, unesite Vaš broj telefona kako bismo vas mogli kontaktirati i izvršiti proces fotografisanja vozila.</p>
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Unesite broj telefona"
                        className="phone-number-input"
                    />
                    <button className="payment-button" onClick={handleGeneratePaymentSlip}>Generišite uplatnicu</button>
                </div>
            )}
            {paymentSlip && (
                <div className="pdf-container">
                    <iframe src={paymentSlip} className="pdf-iframe" />
                </div>
            )}
            <LoadingSpinner loading={loading} />
            <Footer />
        </div>
    );
}

export default KaskoResponseView;
