// LoadingSpinner.js
import React from 'react';
import { ClipLoader } from 'react-spinners';
import '../static/LoadingSpinner.css';

const LoadingSpinner = ({ loading }) => {
    return (
        <div className={`spinner-overlay ${loading ? 'show' : 'hide'}`}>
            <ClipLoader size={150} color={"#123abc"} loading={loading} />
        </div>
    );
};

export default LoadingSpinner;
