import React, { useState } from 'react';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import logo from '../images/logo-Osiguraj-Se.png.png';


const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navigation w-nav">
            <div className="navigation-wrap">
                <a href="/" aria-current="page" className="logo-link w-nav-brand w--current"><img src={logo} width={128} height={40} sizes="128px" alt="" className="logo-image" /></a>
                <div className="menu">
                    <nav role="navigation" className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                        <a href="/about-us" className="navigation-item w-nav-link">O nama</a>
                        <a href="/login" className="navigation-item w-nav-link">Uloguj se</a>
                        <a href="/registration" className="navigation-item w-nav-link">ponude</a>
                        <a href="/login" className="navigation-item w-nav-link">kontakt</a>
                        <a href="/registration" className="navigation-item w-nav-link">registracija</a>
                    </nav>
                    <div className="menu-button w-nav-button"><button className="menu-toggle" onClick={toggleMenu}>
                        ☰
                    </button></div>
                </div>
                <a href="/login" className="button cc-contact-us w-inline-block">
                    <div>prijavi se</div>
                </a>
            </div>
        </div>
    );
};

export default Header;