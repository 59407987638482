import React, { useState } from 'react';
import '../static/login.css'; // Correct relative path
import axios from 'axios'; // Import Axios
import { useNavigate } from "react-router-dom";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import image from '../images/iStock-1442447917 (3).jpg'
import config from '../config'
import Header from './HeaderUser'
import Footer from './Footer'
import LoadingSpinner from './LoadingSpinner';


function SuperAdminPage() {
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [insurancehouse, setInsurancehouse] = useState('');
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [loading, setLoading] = useState(false);




    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleFirstnameChange = (event) => {
        setFirstname(event.target.value);
    };

    const handleLastnameChange = (event) => {
        setLastname(event.target.value);
    };
    const handleInputChange = (event) => {
        setInsurancehouse(event.target.value);
    };
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        // Password validation logic
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (newPassword.length < 8 || !passwordRegex.test(newPassword)) {
            setPasswordStrength('Lozinka mora sadržavati najmanje 8 karaktera, uključujući barem jedan broj.');
        } else {
            setPasswordStrength(calculatePasswordStrength(newPassword));
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        validatePassword(password, newConfirmPassword);
    };

    const validatePassword = (newPassword, newConfirmPassword) => {
        // Password validation logic
        if (newPassword !== newConfirmPassword) {
            setPasswordError('Lozinke se ne podudaraju.');
        } else {
            setPasswordError('');
        }
    };
    const calculatePasswordStrength = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (password.length < 8 || !passwordRegex.test(password)) {
            return('Lozinka mora sadržavati najmanje 8 karaktera, uključujući barem jedan broj.');
        }
        else {
            return('')
        }
    };

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        setLoading(true);

        // Make an HTTP POST request to your Spring Boot backend
        axios.post(`${config.BASE_URL}api/auth/register`, {
            email: email,
            password: password,
            firstname: firstname,
            lastname: lastname,
            role: "ADMIN",
            insurancehouse: insurancehouse
        })
            .then(response => {
                //console.log(response.data.token);
                //console.log(response.data.role);
                //console.log(response.data.lastname);
                // Handle successful login
                //console.log('Login successful');
                if(response.status === 200)
                {
                    //localStorage.setItem("token", response.data.token);
                    //console.log(response.data.token);
                    console.log(response.data.email);
                    alert('Novi admin uspjesno kreiran!');
                    setLoading(false);
                    navigate('/main-super-admin-page');
                }
                else
                {
                    console.log('NE RADI');
                    setLoading(false);
                    alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")

                }
            })
            .catch(error => {
                setLoading(false);
                alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                // Handle login failure
                console.error('Registration failed:', error);
            });
    }
    return (
        <div>
            <Header/>
            <div className="f-account-section-2">
                <div className="f-account-image-wrapper-2"><img src={image} loading="lazy" sizes="(max-width: 767px) 100vw, 45vw"  alt="" className="f-image-cover-3" /></div>
                <div className="f-account-container-r-3">
                    <div className="f-account-content-wrapper-2">
                        <div className="f-margin-bottom-27">
                            <h5 className="f-h5-heading-2">Registracija novog admina iz osiguravajace kuce</h5>
                        </div>
                        <div className="f-account-form-block-2 w-form">
                            <form id="wf-form-Sign-Up-Form" name="wf-form-Sign-Up-Form" data-name="Sign Up Form" method="get" data-wf-page-id="654d72cb39b825436172ef79" data-wf-element-id="c83dc95b-3fa8-c3d7-9dfd-cca5ef5bf362" onSubmit={handleRegistration}>
                                <div className="w-layout-grid f-account-input-grid-2">
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Ime</div><input type="text" className="f-field-input-2 w-input" maxLength={256} name="Name-Field-01" data-name="Name Field 01" placeholder="Vaše ime..." id="firstname"
                                                                                         value={firstname}
                                                                                         onChange={handleFirstnameChange} />
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Prezime</div><input type="text" className="f-field-input-2 w-input" maxLength={256} name="Name-Field-01" data-name="Name Field 01" placeholder="Vaše prezime..." id="lastname"
                                                                                             value={lastname}
                                                                                             onChange={handleLastnameChange} />
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">E-mail</div><input type="email" className="f-field-input-2 w-input" maxLength={256} name="Email-Field-01" data-name="Email Field 01" placeholder="Vaš e-mail..." id="username"
                                                                                            value={email}
                                                                                            onChange={handleEmailChange} />
                                    </div>
                                    <select id="insrancehouse" name="insrancehouse" data-name="Field 3" className="select-field-2 w-select" value={insurancehouse} onChange={handleInputChange}>
                                        <option value="">Izaberite osiguravajucu kucu...</option>
                                        <option value="GRAWE">GRAWE</option>
                                        <option value="ADRIATIC">ADRIATIC</option>
                                        <option value="TRIGLAV">TRIGLAV</option>
                                        <option value="WIENER">WIENER</option>
                                        <option value="UNIQA">UNIQA</option>
                                        <option value="PREMIUM">PREMIUM</option>
                                    </select>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Lozinka</div><input type="password" className="f-field-input-2 w-input" maxLength={256} name="Password-Field-01" data-name="Password Field 01" placeholder="Unesite lozinku..."  id="password"
                                                                                             value={password}
                                                                                             onChange={handlePasswordChange} />
                                        {password && (
                                            <div className={`password-strength ${passwordStrength}`}>
                                                {passwordStrength}
                                            </div>
                                        )}
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Ponovite lozinku</div><input type="password" className="f-field-input-2 w-input" maxLength={256} name="Password-Field-01" data-name="Password Field 01" placeholder="Potvrdite lozinku..."   id="confirmPassword"
                                                                                                      value={confirmPassword}
                                                                                                      onChange={handleConfirmPasswordChange}/>

                                    </div>
                                    {passwordError && <div className="password-error">{passwordError}</div>}
                                </div>
                                <div className="f-account-form-button-2"> <input
                                    type="submit"
                                    value="Registracija"
                                    data-wait="Please wait..."
                                    className={`f-button-neutral-2 w-button ${!(passwordError === '' && passwordStrength !== '') ? 'disabled' : ''}`}
                                    disabled={passwordError === '' && passwordStrength !== ''}
                                /></div>
                            </form>
                            <LoadingSpinner loading={loading} />
                            <div className="f-success-message-2 w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SuperAdminPage;