import React from 'react';
import {useNavigate} from "react-router-dom";
import Header from './Header';
import HeaderUser from './HeaderUser';
import Footer from './Footer';
import HeaderAdmin from "./HeaderAdmin";
import browserFrameImg from "../images/img_browser_frame.png";
import aboutUsImg from "../images/about_us_second_image.png";
import wiener from '../images/WIENER-Logo-e1508417609390.jpg';
import adriatic from '../images/download.png';


import '../static/normalize.css';
import '../static/webflow.css';
import '../static/aboutus.webflow.css';


function AboutUs() {
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
    };

    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');


    return (
        <div>
            {token ? (
                role === 'USER' ? <HeaderUser/> : <HeaderAdmin/>
            ) : (
                <Header/>
            )}
            <div className="section cc-home-wrap">
                <div className="a-header-section">
                    <div className="a-container-regular">
                        <div id="w-node-_5c15750e-572a-955d-3783-535311cc1966-737b4948" className="a-header-block">
                            <div className="a-header-content">
                                <div className="a-margin-bottom-16">
                                    <div className="a-detail-small a-text-color-primary">o nama</div>
                                </div>
                                <div className="a-margin-bottom-48">
                                    <h1 className="a-h1-heading">Prva online osiguravajuća <span
                                        className="a-span-underline">platforma.</span></h1>
                                </div>
                                <p className="a-paragraph-regular">Naša misija je da proces osiguranja učinimo što
                                    jednostavnijim i transparentnijim.</p>
                                <div className="a-header-button-wrapper">
                                    <a href="/registration" className="a-button-primary w-button">Započni</a>
                                    <a href="/" className="a-button-video w-inline-block">
                                        <div>Kako funkcioniše</div>
                                        <div className="a-header-play">
                                            <div className="a-icon-small w-embed">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16}
                                                     viewBox="0 0 16 16" fill="none">
                                                    <path
                                                        d="M4.61835 4.54669V11.4534C4.61835 11.98 5.19835 12.3 5.64501 12.0134L11.0717 8.56003C11.485 8.30003 11.485 7.70003 11.0717 7.43336L5.64501 3.98669C5.19835 3.70003 4.61835 4.02003 4.61835 4.54669Z"
                                                        fill="currentColor"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="a-caption">U samo nekoliko klikova možete dobiti ponude za Vaše
                                    osiguranje od nekoliko osiguravajućih kuća i odabrati onu koja Vama najviše
                                    odgovara.
                                </div>
                            </div>
                            <div className="a-header-logo-wrapper">
                                <div className="a-paragraph-small"><span className="a-text-color-primary">5+ osiguravajućih kuca</span>
                                </div>
                                <div className="a-header-logo-block">
                                    <img src="../images/csm_GRAWE_LOGO_2f35c35f30.png" loading="lazy" width={88} alt=""
                                         className="a-header-logo"/>
                                    <img src={adriatic} loading="lazy" width={88} alt=""
                                         className="a-header-logo"/>
                                    <img src="images/Premium-Logo-og-2023.png" loading="lazy" width={82} sizes="82px"
                                         alt=""
                                         srcSet="images/Premium-Logo-og-2023-p-500.png 500w, images/Premium-Logo-og-2023-p-800.png 800w, images/Premium-Logo-og-2023-p-1080.png 1080w, images/Premium-Logo-og-2023.png 1200w"
                                         className="a-header-logo"/>
                                    <img src="images/Triglav-web.jpg" loading="lazy" width={89} sizes="89px" alt=""
                                         srcSet="images/Triglav-web-p-500.jpg 500w, images/Triglav-web.jpg 800w"
                                         className="a-header-logo"/>
                                    <img src={wiener} loading="lazy" width={109} alt=""
                                         className="a-header-logo"/></div>
                            </div>
                        </div>
                    </div>
                    <div className="a-header-image-block">
                        <img src={browserFrameImg} alt="browser_frame" className="a-header-background-image"/>
                    </div>
                </div>
                <div className="a-header-section">
                    <div id="w-node-ff27dba5-9f8a-aff6-1f11-ce174c42e85c-737b4948" className="text-block">
                        <div className="lynx-subtitle">osiguraj-se</div>
                        <h2 className="lynx-heading">Dobrodošli na našu platformu za osiguranje Vaše centralno
                            mjesto za sve vaše potrebe osiguranja. </h2>
                        <p className="lynx-paragraph-3">Mi smo inovativni servis koji povezuje klijente sa više
                            osiguravajućih kuća, omogućavajući vam da jednostavno i brzo dobijete najbolje
                            ponude za sve vrste osiguranja.</p>
                        <a href="#" className="lynx-button w-button">saznaj više</a>
                    </div>
                    <div className="a-header-image-block">
                        <img src={aboutUsImg} alt="browser_frame" className="a-header-background-image"/>
                    </div>
                </div>

                <div className="container">
                    <div className="motto-wrap"/>
                    <div className="divider"/>
                    <div className="about-story-wrap">
                        <p className="paragraph-light">Naša misija je da proces osiguranja učinimo što jednostavnijim i transparentnijim. Vjerujemo da svaki klijent zaslužuje priliku da izabere najbolju moguću ponudu prilagođenu svojim potrebama i budžetu. Kroz našu platformu, možete podnijeti zahtjev za osiguranje i u kratkom roku dobiti ponude od različitih osiguravajućih kuća.<br /><br />Mi vjerujemo da osiguranje ne treba da bude komplikovano. Naš cilj je da vam pružimo jednostavan, brz i transparentan način da dobijete osiguranje koje Vam je potrebno. Pridružite nam se i otkrijte novi, bolji način osiguranja.</p>
                    </div>
                    <div className="divider"/>
                </div>
            </div>
            <div>
                <div className="lynx-block-center">
                    <div className="lynx-subtitle">Usluge</div>
                    <h2 className="lynx-heading">Ono što vam nudimo</h2>
                </div>
                <div className="deals-container">
                    <div id="w-node-a1fc3421-222e-f730-525b-0aa6ca3e6bd0-737b4948" className="lynx-features">
                        <div className="lynx-block-features-pink-2"><img src="../images/da (3).jpg" loading="lazy"
                                                                         alt="" className="lynx-icon-features-small-2"/>
                        </div>
                        <h6 className="lynx-heading-features-small-2">Jednostavnost</h6>
                        <p className="lynx-paragraph-features-2">Popunite jedan zahtjev i dobijte više ponuda bez
                            potrebe da kontaktirate svaku osiguravajuću kuću pojedinačno.</p>
                    </div>
                    <div id="w-node-a1fc3421-222e-f730-525b-0aa6ca3e6bd7-737b4948" className="lynx-features">
                        <div className="lynx-block-features-purple-2"><img src="../images/da (1).jpg" loading="lazy"
                                                                           alt=""
                                                                           className="lynx-icon-features-small-2"/>
                        </div>
                        <h6 className="lynx-heading-features-small-2">Brzina</h6>
                        <p className="lynx-paragraph-features-2">U toku smo s vremenom - u učestaloj digitalizaciji,
                            uočili smo priliku da proces osiguranja učinimo bržim i jednotavnijim.</p>
                    </div>
                    <div id="w-node-a1fc3421-222e-f730-525b-0aa6ca3e6bde-737b4948" className="lynx-features">
                        <div className="lynx-block-features-green-2"><img src="../images/da (2).jpg" loading="lazy"
                                                                          alt=""
                                                                          className="lynx-icon-features-small-2"/></div>
                        <h6 className="lynx-heading-features-small-2">Transparentnost</h6>
                        <p className="lynx-paragraph-features-2">Sve ponude koje dobijete prikazane su jasno i detaljno,
                            omogućavajući vam da lako uporedite uslove, cijene i pokrića.</p>
                    </div>
                    <div id="w-node-a1fc3421-222e-f730-525b-0aa6ca3e6be5-737b4948" className="lynx-features">
                        <div className="lynx-block-features-yellow"><img src="../images/op.jpg" loading="lazy" alt=""
                                                                         className="lynx-icon-features-small-2"/></div>
                        <h6 className="lynx-heading-features-small-2">Podrška</h6>
                        <p className="lynx-paragraph-features-2">Popunite jedan zahtjev i dobijte više ponuda bez
                            potrebe da kontaktirate svaku osiguravajuću kuću pojedinačno. Naša platforma vam štedi vrijeme
                            i energiju. </p>
                    </div>
                </div>
            </div>
            <div className="lynx-content-3">
                <div className="lynx-block-center">
                    <div className="lynx-subtitle">Vrste usluga</div>
                    <h2 className="lynx-heading">Sva osiguranja na jednom mjestu</h2>
                </div>
                <div className="w-layout-grid lynx-grid-features-2 all-insurances-container">
                    <div id="w-node-_2f62dafe-0955-0b2f-78b6-6f4f2feaf7a8-737b4948" className="lynx-features">
                        <div className="lynx-block-features"><img src="../images/iStock-954235570 (1).jpg"
                                                                  loading="lazy" sizes="(max-width: 1439px) 32px, 82px"
                                                                  srcSet="../images/iStock-954235570 (1).jpg 500w, ../images/iStock-954235570 (1).jpg 800w, ../images/iStock-954235570 (1).jpg 1080w, ../images/iStock-954235570 (1).jpg 1200w"
                                                                  alt="" className="lynx-icon-features"/></div>
                        <h5 className="lynx-heading-features">Kasko osiguranje</h5>
                        <p className="lynx-paragraph-features-2">Kasko osiguranje za sve vrste vozila.</p>
                    </div>
                    <div id="w-node-_2f62dafe-0955-0b2f-78b6-6f4f2feaf7af-737b4948" className="lynx-features">
                        <div className="lynx-block-features"><img src="../images/iStock-1409298953 (1).jpg"
                                                                  loading="lazy" sizes="(max-width: 1439px) 32px, 82px"
                                                                  srcSet="../images/iStock-1409298953 (1).jpg 500w, ../images/iStock-1409298953 (1).jpg 800w, ../images/iStock-1409298953 (1).jpg 1080w, ../images/iStock-1409298953 (1).jpg 1600w, ../images/iStock-1409298953 (1).jpg 2000w, ../images/iStock-1409298953 (1).jpg 2600w, ../images/iStock-1409298953 (1).jpg 3200w, ../images/iStock-1409298953 (1).jpg 3864w"
                                                                  alt="" className="lynx-icon-features"/></div>
                        <h5 className="lynx-heading-features">Osiguranje imovine</h5>
                        <p className="lynx-paragraph-features-2">Osigurajte kuću, stan ili poslovni prostor.</p>
                    </div>
                    <div id="w-node-_2f62dafe-0955-0b2f-78b6-6f4f2feaf7b6-737b4948" className="lynx-features">
                        <div className="lynx-block-features"><img src="../images/iStock-1477970232 (2).jpg"
                                                                  loading="lazy" sizes="(max-width: 1439px) 32px, 82px"
                                                                  srcSet="../images/iStock-1477970232 (2).jpg 500w, ../images/iStock-1477970232 (2).jpg 800w, ../images/iStock-1477970232 (2).jpg 1080w, ../images/iStock-1477970232 (2).jpg 1600w, ../images/iStock-1477970232 (2).jpg 2000w, ../images/iStock-1477970232 (2).jpg 2600w, ../images/iStock-1477970232 (2).jpg 3200w, ../images/iStock-1477970232 (2).jpg 6479w"
                                                                  alt="" className="lynx-icon-features"/></div>
                        <h5 className="lynx-heading-features">Životno osiguranje</h5>
                        <p className="lynx-paragraph-features-2">Životno osiguranje prema vašim preferencijama.</p>
                    </div>
                </div>
            </div>
            <div className="section cc-cta">
                <div className="container">
                    <div className="cta-wrap">
                        <div>
                            <div className="cta-text">
                                <div className="heading-jumbo-small">Osiguraj sebe i svoju imovinu<br/></div>
                                <div className="paragraph-bigger cc-bigger-light">Danas je dan da zaštitite svoju
                                    budućnost. Registracijom na našem sajtu, korak po korak, pravite sigurnije sutra. Ne
                                    čekajte više - započnite put prema mirnijem životu danas.<br/></div>
                            </div>
                            <a href="/registration" className="button cc-jumbo-button w-inline-block">
                                <div>registruj se</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>


    );
}

export default AboutUs;
