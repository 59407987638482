import React, { useState, useEffect } from 'react';
import '../static/login.css'; // Correct relative path
import axios from 'axios'; // Import Axios
import { useNavigate, useLocation } from "react-router-dom";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import image from '../images/iStock-1442447917-2.jpg';
import config from '../config';
import Header from './Header';
import Footer from './Footer';
import LoadingSpinner from './LoadingSpinner';

function SetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const navigate = useNavigate();
    const location = useLocation(); // To get query parameters
    const [loading, setLoading] = useState(false);


    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    const id = getQueryParams().get('id');

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        // Password validation logic
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (newPassword.length < 8 || !passwordRegex.test(newPassword)) {
            setPasswordStrength('Lozinka mora sadržavati najmanje 8 karaktera, uključujući barem jedan broj.');
        } else {
            setPasswordStrength(calculatePasswordStrength(newPassword));
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        validatePassword(password, newConfirmPassword);
    };

    const validatePassword = (newPassword, newConfirmPassword) => {
        // Password validation logic
        if (newPassword !== newConfirmPassword) {
            setPasswordError('Lozinke se ne podudaraju.');
        } else {
            setPasswordError('');
        }
    };

    const calculatePasswordStrength = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        if (password.length < 8 || !passwordRegex.test(password)) {
            return 'Lozinka mora sadržavati najmanje 8 karaktera, uključujući barem jedan broj.';
        }
        else {
            return '';
        }
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        setLoading(true);
        console.log("Password:", password);
        console.log("Code (id):", id);

        // Make an HTTP PATCH request to your Spring Boot backend
        axios.patch(`${config.BASE_URL}api/auth/set-password`, {
            password: password,
            code: id
        })
            .then(response => {
                if (response.status === 200) {
                    alert('Lozinka je uspješno resetovana!');
                    setLoading(false);
                    navigate('/login');
                } else {
                    setLoading(false);
                    alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                    console.log('Resetovanje lozinke nije uspjelo.');
                }
            })
            .catch(error => {
                setLoading(false);
                alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                console.error('Resetovanje lozinke nije uspjelo:', error);
            });
    }

    return (
        <div>
            <Header />
            <div className="f-account-section-2">
                <div className="f-account-image-wrapper-2"><img src={image} loading="lazy" sizes="(max-width: 767px) 100vw, 45vw" alt="" className="f-image-cover-3" /></div>
                <div className="f-account-container-r-3">
                    <div className="f-account-content-wrapper-2">
                        <div className="f-margin-bottom-27">
                            <h5 className="f-h5-heading-2">Resetovanje vaše lozinke</h5>
                        </div>
                        <div className="f-account-form-block-2 w-form">
                            <form id="wf-form-Sign-Up-Form" name="wf-form-Sign-Up-Form" data-name="Sign Up Form" method="get" data-wf-page-id="654d72cb39b825436172ef79" data-wf-element-id="c83dc95b-3fa8-c3d7-9dfd-cca5ef5bf362" onSubmit={handleRegistration}>
                                <div className="w-layout-grid f-account-input-grid-2">
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Nova Lozinka</div>
                                        <input type="password" className="f-field-input-2 w-input" maxLength={256} name="Password-Field-01" data-name="Password Field 01" placeholder="Unesite lozinku..." id="password"
                                               value={password}
                                               onChange={handlePasswordChange} />
                                        {password && (
                                            <div className={`password-strength ${passwordStrength}`}>
                                                {passwordStrength}
                                            </div>
                                        )}
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Ponovite novu lozinku</div>
                                        <input type="password" className="f-field-input-2 w-input" maxLength={256} name="Password-Field-01" data-name="Password Field 01" placeholder="Potvrdite lozinku..." id="confirmPassword"
                                               value={confirmPassword}
                                               onChange={handleConfirmPasswordChange} />
                                    </div>
                                    {passwordError && <div className="password-error">{passwordError}</div>}
                                </div>
                                <div className="f-account-form-button-2">
                                    <input
                                        type="submit"
                                        value="Resetujte lozinku"
                                        data-wait="Please wait..."
                                        className={`f-button-neutral-2 w-button ${!(passwordError === '' && passwordStrength !== '') ? 'disabled' : ''}`}
                                        disabled={passwordError === '' && passwordStrength !== ''}
                                    />
                                </div>
                            </form>
                            <LoadingSpinner loading={loading} />
                            <div className="f-success-message-2 w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SetPassword;
