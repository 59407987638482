import React, { useState } from 'react';
import '../static/login.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import image from '../images/iStock-1442447917 (3).jpg';
import config from '../config';
import Footer from './FooterUser';
import LoadingSpinner from './LoadingSpinner';
import HeaderUser from "./HeaderUser";
import HeaderAdmin from "./HeaderAdmin";


function AccountUpdatePage() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [loading, setLoading] = useState(false);
    const role = localStorage.getItem('role');


    const [policyData, setPolicyData] = useState({
        password: ''
    });

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setPolicyData({ ...policyData, password: newPassword });
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!#%&]{8,}$/;
        if (newPassword.length < 8 || !passwordRegex.test(newPassword)) {
            setPasswordStrength('Lozinka mora sadržavati najmanje 8 karaktera, uključujući barem jedan broj.');
        } else {
            setPasswordStrength('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        validatePassword(password, newConfirmPassword);
    };

    const validatePassword = (newPassword, newConfirmPassword) => {
        if (newPassword !== newConfirmPassword) {
            setPasswordError('Lozinke se ne podudaraju.');
        } else {
            setPasswordError('');
        }
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        setLoading(true);

        if (passwordError === '' && passwordStrength === '') {
            const jwtToken = localStorage.getItem("token");

            fetch(`${config.BASE_URL}api/auth/update`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(policyData),
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response from the backend as needed.
                    console.log(data);
                    setLoading(false);
                    alert('Promjene uspjesno sacuvane!');
                    if (data.role === 'USER') {
                        navigate('/main-page');
                    } else if (data.role === 'ADMIN') {
                        navigate('/main-admin-page');
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    alert("Doslo je do problema molimo da probate kasnije!")
                    console.error('Error:', error);
                });
        }
    };

    return (
        <div>
            {role === 'USER' ? <HeaderUser /> : <HeaderAdmin />}
            <div className="f-account-section-2">
                <div className="f-account-image-wrapper-2">
                    <img src={image} loading="lazy" sizes="(max-width: 767px) 100vw, 45vw" alt="" className="f-image-cover-3" />
                </div>
                <div className="f-account-container-r-3">
                    <div className="f-account-content-wrapper-2">
                        <div className="f-margin-bottom-27">
                            <h5 className="f-h5-heading-2">Promjeniti vašu lozinku</h5>
                        </div>
                        <div className="f-account-form-block-2 w-form">
                            <form id="wf-form-Sign-Up-Form" name="wf-form-Sign-Up-Form" data-name="Sign Up Form" method="get" data-wf-page-id="654d72cb39b825436172ef79" data-wf-element-id="c83dc95b-3fa8-c3d7-9dfd-cca5ef5bf362" onSubmit={handleRegistration}>
                                <div className="w-layout-grid f-account-input-grid-2">
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Unesite novu lozinku</div>
                                        <input type="password" className="f-field-input-2 w-input" maxLength={256} name="Password-Field-01" data-name="Password Field 01" placeholder="Unesite lozinku..." id="password"
                                               value={password}
                                               onChange={handlePasswordChange} />
                                        {passwordStrength && (
                                            <div className="password-strength">
                                                {passwordStrength}
                                            </div>
                                        )}
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Ponovite lozinku</div>
                                        <input type="password" className="f-field-input-2 w-input" maxLength={256} name="Password-Field-01" data-name="Password Field 01" placeholder="Potvrdite lozinku..." id="confirmPassword"
                                               value={confirmPassword}
                                               onChange={handleConfirmPasswordChange} />
                                    </div>
                                    {passwordError && <div className="password-error">{passwordError}</div>}
                                </div>
                                <div className="f-account-form-button-2">
                                    <input
                                        type="submit"
                                        value="Sacuvajte promjene"
                                        data-wait="Please wait..."
                                        className={`f-button-neutral-2 w-button ${passwordError || passwordStrength ? 'disabled' : ''}`}
                                        disabled={passwordError || passwordStrength}
                                    />
                                </div>
                            </form>
                            <LoadingSpinner loading={loading} />
                            <div className="f-success-message-2 w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {role === 'USER' ? <Footer /> : <Footer />}
        </div>
    );
}

export default AccountUpdatePage;
