import React, { useState } from 'react';
import '../static/login.css'; // Correct relative path
import axios from 'axios'; // Import Axios
import { useNavigate } from "react-router-dom";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import image from '../images/iStock-1442447917-2.jpg'
import config from '../config'
import Header from './Header'
import Footer from './Footer'
import LoadingSpinner from "./LoadingSpinner";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        setLoading(true);


        // Make an HTTP POST request to your Spring Boot backend
        axios.patch(`${config.BASE_URL}api/auth/forgot-password`, {
            email: email
        })
            .then(response => {
                //console.log(response.data.token);
                //console.log(response.data.role);
                //console.log(response.data.lastname);
                // Handle successful login
                //console.log('Login successful');
                if(response.status === 200)
                {
                    //localStorage.setItem("token", response.data.token);
                    //console.log(response.data.token);
                    console.log(response.data.email);
                    setLoading(false);
                    alert('Provjerite mejl i resetujte vašu lozinku!');
                    navigate('/login');
                }
                else
                {
                    console.log('NE RADI');
                    setLoading(false);
                    alert('Doslo je do greske molimo pokusajte opet!');



                }
            })
            .catch(error => {
                // Handle login failure
                console.error('Update failed:', error);
                setLoading(false);
                alert('Doslo je do greske molimo pokusajte opet!');
            });
    }
    return (
        <div>
            <Header/>
            <div className="f-account-section-2">
                <div className="f-account-image-wrapper-2"><img src={image} loading="lazy" sizes="(max-width: 767px) 100vw, 45vw"  alt="" className="f-image-cover-3" /></div>
                <div className="f-account-container-r-3">
                    <div className="f-account-content-wrapper-2">
                        <div className="f-margin-bottom-27">
                            <h5 className="f-h5-heading-2">Resetovanje vaše lozinke</h5>
                        </div>
                        <div className="f-account-form-block-2 w-form">
                            <form id="wf-form-Sign-Up-Form" name="wf-form-Sign-Up-Form" data-name="Sign Up Form" method="get" data-wf-page-id="654d72cb39b825436172ef79" data-wf-element-id="c83dc95b-3fa8-c3d7-9dfd-cca5ef5bf362" onSubmit={handleRegistration}>
                                <div className="f-field-wrapper-2">
                                    <div className="f-field-label-2">E-mail</div><input type="email" className="f-field-input-2 w-input" maxLength={256} name="Email-Field-01" data-name="Email Field 01" placeholder="Vaš e-mail..." id="username"
                                                                                        value={email}
                                                                                        onChange={handleEmailChange} />
                                </div>
                                <div className="f-account-form-button-2">
                                    <input
                                        type="submit"
                                        value="Posalji kod za resetovanje"
                                        className="f-button-neutral-2 w-button"
                                    />
                                </div>
                            </form>
                            <LoadingSpinner loading={loading} />
                            <div className="f-success-message-2 w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div className="w-form-fail">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPassword;