import React, { useState } from 'react';
import '../static/MainPage.css'; // Correct relative path
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import { useNavigate } from "react-router-dom";
import config from '../config';
import Header from './HeaderUser';
import Footer from './FooterUser';
import LoadingSpinner from './LoadingSpinner';


function CarInsuranceRequest() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    const [policyData, setPolicyData] = useState({
        firstname: '',
        lastname: '',
        newornot: false,
        year: '',
        brand: '',
        mileage: 0,
        kilowatts: 0,
        type: 'CAR',
        company: 'x',
        capacity: 1,
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setPolicyData({
            ...policyData,
            [name]: inputValue,
        });
    };

    const jwtToken = localStorage.getItem("token");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // Check if any required field is empty
        const requiredFields = ['firstname', 'lastname', 'year', 'brand', 'mileage', 'kilowatts'];
        for (const field of requiredFields) {
            if (policyData[field] === '' || policyData[field] === null || policyData[field] === undefined) {
                alert('Sva polja su obavezna!');
                setLoading(false);
                return;
            }
        }

        // Send the policyData to the backend using the fetch API or Axios.
        console.log("PODACI:", policyData);
        console.log(jwtToken);

        fetch(`${config.BASE_URL}api/policyreq/kasko`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(policyData),
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the response from the backend as needed.
                console.log(data);
                setLoading(false);
                localStorage.setItem('id', data.id);
                localStorage.setItem('type','KASKO');
                navigate('/main-page/confirmation');
            })
            .catch((error) => {
                setLoading(false);
                alert("Doslo je do problema, molimo da probate ponovo!")
                console.error('Error:', error);
            });
    };

    return (
        <div>
            <Header />
            <section>
                <h1 className="heading">Unesite podatke o svom vozilu</h1>
            </section>
            <div className="columns w-row kasko-form-container">
                <div className="w-col w-col-6">
                    <div className="w-form">
                        <form id="email-form" name="email-form" onSubmit={handleSubmit} data-name="Email Form" data-wf-page-id="655606539083e607ba6bc88b" data-wf-element-id="d107ecf6-3a82-f695-7248-e25f860511bc">
                            <label htmlFor="firstname">Ime</label>
                            <input type="text" className="text-field-2 w-input" maxLength={256} name="firstname" data-name="Name" placeholder id="firstname" value={policyData.firstname} onChange={handleInputChange} />
                            <label htmlFor="lastname">prezime</label>
                            <input type="text" className="text-field-3 w-input" maxLength={256} name="lastname" data-name="Name 10" placeholder id="lastname" value={policyData.lastname} onChange={handleInputChange} />
                            <label htmlFor="newornot">Stanje</label>
                            <select id="newornot" name="newornot" data-name="Field 3" className="select-field-2 w-select" value={policyData.newornot} onChange={handleInputChange}>
                                <option value="">Izaberite jedno...</option>
                                <option value="true">Novo</option>
                                <option value="false">Polovno</option>
                            </select>
                            <label htmlFor="year">godina proizvodnje</label>
                            <label htmlFor="yearNote">Napomena: vozila starija od 10 godina se ne mogu osigurati</label>
                            <select id="year" name="year" data-name="Field 4" required className="select-field-3 w-select" value={policyData.year} onChange={handleInputChange}>
                                <option value="">Izaberite jedno...</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                            </select>
                            <label htmlFor="brand">marka i model vozila (kao u saobracajnoj)</label>
                            <input id="brand" name="brand" data-name="Field 5" className="text-field-4 w-input" maxLength={256} value={policyData.brand} onChange={handleInputChange}/>
                            <label htmlFor="kilowatts">kilovati</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="kilowatts" data-name="Name 5" placeholder id="kilowatts" value={policyData.kilowatts} onChange={handleInputChange} />
                            <label htmlFor="mileage">pređena kilometraža</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="mileage" data-name="Name 5" placeholder id="mileage" value={policyData.mileage} onChange={handleInputChange} />
                            <label htmlFor="mileage">Kubikaza motora</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="capacity" data-name="Name 5" placeholder id="capacity" value={policyData.capacity} onChange={handleInputChange} />
                            <div className="w-layout-blockcontainer w-container kasko-form-footer">
                                <button type="submit" className="button-3 w-button">Kreiraj zahtjev za kasko polisu</button>
                            </div>
                        </form>
                        <LoadingSpinner loading={loading} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CarInsuranceRequest;
