import React, { useState } from 'react';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import logo from '../images/logo-Osiguraj-Se.png.png';

const HeaderAdmin = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.location.href = '/login';
    };

    return (
        <div data-collapse="medium" data-animation="default" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navigation w-nav">
            <div className="navigation-wrap">
                <a href="/main-admin-page" className="logo-link w-nav-brand"><img src={logo} width={128} height={40} sizes="128px" alt="" className="logo-image" /></a>
                <div className="menu">
                    <nav role="navigation" className={`nav-menu ${menuOpen ? 'open' : ''}`}>
                        <a href="/main-admin-page/choose" className="navigation-item w-nav-link">Zahtjevi</a>
                        <a href="/main-admin-page/kasko-requests" className="navigation-item w-nav-link">Kasko</a>
                        <a href="/main-admin-page/property-requests" className="navigation-item w-nav-link">Imovinsko</a>
                        <a href="/account-update" className="navigation-item w-nav-link">Nalog</a>
                        {menuOpen && (
                            <a onClick={handleLogout} className="navigation-item w-nav-link">Odjavi se</a>
                        )}
                    </nav>
                    <div className="menu-button w-nav-button"><button className="menu-toggle" onClick={toggleMenu}>
                        ☰
                    </button></div>
                </div>
                <button onClick={handleLogout} className="button cc-contact-us w-inline-block">
                    <div>Odjavi se</div>
                </button>
            </div>
        </div>
    );
};

export default HeaderAdmin;
