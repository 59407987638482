import React, { useState } from 'react';
import axios from 'axios';
import '../static/MainPage.css';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import Header from './HeaderUser';
import Footer from './FooterUser';
import LoadingSpinner from './LoadingSpinner';
import config from "../config";

function LifeInsuranceRequest() {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        ime: '',
        prezime: '',
        datumRodjenja: '',
        brojTelefona: '',
        pol: '',
        grad: '',
        mjesecniIznos: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple validation
        const requiredFields = ['ime', 'prezime', 'datumRodjenja', 'brojTelefona', 'pol', 'grad', 'mjesecniIznos'];
        for (const field of requiredFields) {
            if (formData[field] === '') {
                alert('Sva polja su obavezna!');
                return;
            }
        }

        setLoading(true);

        axios.post(`${config.BASE_URL}api/send-life-insurance-email`, formData)
            .then(response => {
                alert('Vaš zahtev je uspešno poslat!');
            })
            .catch(error => {
                console.error('There was an error sending the request!', error);
                alert('Došlo je do greške pri slanju zahteva.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            <Header />
            <section>
                <h1 className="heading">Unesite podatke za životno osiguranje</h1>
            </section>
            <div className="columns w-row kasko-form-container">
                <div className="w-col w-col-6">
                    <div className="w-form">
                        <form id="email-form" name="email-form" onSubmit={handleSubmit}>
                            <label htmlFor="ime">Ime</label>
                            <input type="text" className="text-field-2 w-input" maxLength={256} name="ime" placeholder="Ime" value={formData.ime} onChange={handleInputChange} />
                            <label htmlFor="prezime">Prezime</label>
                            <input type="text" className="text-field-3 w-input" maxLength={256} name="prezime" placeholder="Prezime" value={formData.prezime} onChange={handleInputChange} />
                            <label htmlFor="datumRodjenja">Datum rođenja</label>
                            <input type="date" className="text-field-4 w-input" name="datumRodjenja" value={formData.datumRodjenja} onChange={handleInputChange} />
                            <label htmlFor="brojTelefona">Broj telefona</label>
                            <input type="tel" className="text-field-4 w-input" maxLength={15} name="brojTelefona" placeholder="Broj telefona" value={formData.brojTelefona} onChange={handleInputChange} />
                            <label htmlFor="pol">Pol</label>
                            <select name="pol" className="select-field-3 w-select" value={formData.pol} onChange={handleInputChange}>
                                <option value="">Izaberite pol...</option>
                                <option value="Muški">Muški</option>
                                <option value="Ženski">Ženski</option>
                            </select>
                            <label htmlFor="grad">Grad</label>
                            <input type="text" className="text-field-4 w-input" maxLength={256} name="grad" placeholder="Grad" value={formData.grad} onChange={handleInputChange} />
                            <label htmlFor="mjesecniIznos">Mesečni iznos koji želite izdvajati (KM)</label>
                            <input type="number" className="text-field-4 w-input" maxLength={256} name="mjesecniIznos" placeholder="Mesečni iznos" value={formData.mjesecniIznos} onChange={handleInputChange} min="0" />

                            <div className="w-layout-blockcontainer w-container kasko-form-footer">
                                <button type="submit" className="button-3 w-button">Pošaljite zahtev</button>
                            </div>
                        </form>
                        <LoadingSpinner loading={loading} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LifeInsuranceRequest;
