import './App.css';
import LandingPage from './component/LandingPage';
import LoginPage from "./component/LoginPage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from "./component/MainPage";
import RegistrationPage from "./component/RegistrationPage";
import MainAdminPage from "./component/MainAdminPage";
import KaskoFirstPage from "./component/KaskoFirstPage";
import CarInsuranceRequest from "./component/CarInsuranceRequest";
import MotorcycleInsuranceRequest from "./component/MotorcycleInsuranceRequest";
import TruckInsuranceRequest from "./component/TruckInsuranceRequest";
import PropertyInsuranceRequest from "./component/PropertyInsuranceRequest";
import ConfirmationPage from "./component/ConfirmationPage";
import KaskoRequestsView from "./component/KaskoRequestsView";
import KaskoResponseView from "./component/KaskoResponseView";
import SuperAdminPage from "./component/SuperAdminPage";
import AdminResponseChooseView from "./component/AdminResponseChooseView";
import ClientResponseChooseView from "./component/ClientResponseChooseView";
import PropertyResponseView from "./component/PropertyResponseView";
import PropertyRequestsView from "./component/PropertyRequestsView";
import AccountUpdatePage from "./component/AccountUpdatePage";
import AboutUs from "./component/AboutUs";
import ForgotPassword from "./component/ForgotPassword";
import SetPassword from "./component/SetPassword";
import ProtectedRoute from "./component/ProtectedRoute";
import LifeInsuranceRequest from "./component/LifeInsuranceRequest";
import TermsAndConditions from "./component/TermsAndConditions";



function App() {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    const checkCondition = () => {
            //return !!token;
        return true;
        };
    const checkAdminCondition = () => {
       // return 'ADMIN'=== role && !!token
        return true;

    };
    const checkSuperAdminCondition = () => {
        //return 'SUPERADMIN'=== role && !!token
        return true;

    };
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/main-page" element={<ProtectedRoute element={<MainPage />} condition={checkCondition} />} />
                <Route path="/kasko" element={<ProtectedRoute element={<MainPage />} condition={checkCondition} />} />
                <Route path="/registration" element={<ProtectedRoute element={<RegistrationPage />} condition={checkCondition} />} />
                <Route path="/main-admin-page" element={<ProtectedRoute element={<MainAdminPage />} condition={checkAdminCondition} />} />
                <Route path="/main-super-admin-page" element={<ProtectedRoute element={<SuperAdminPage />} condition={checkSuperAdminCondition} />} />
                <Route path="/main-admin-page/kasko-requests" element={<ProtectedRoute element={<KaskoRequestsView />} condition={checkAdminCondition} />} />
                <Route path="/main-admin-page/property-requests" element={<ProtectedRoute element={<PropertyRequestsView />} condition={checkAdminCondition} />} />
                <Route path="/main-page/kasko" element={<ProtectedRoute element={<KaskoFirstPage />} condition={checkCondition} />} />
                <Route path="/main-page/kasko/car" element={<ProtectedRoute element={<CarInsuranceRequest />} condition={checkCondition} />} />
                <Route path="/main-page/kasko/motorcycle" element={<ProtectedRoute element={<MotorcycleInsuranceRequest />} condition={checkCondition} />} />
                <Route path="/main-page/kasko/truck" element={<ProtectedRoute element={<TruckInsuranceRequest />} condition={checkCondition} />} />
                <Route path="/main-page/property" element={<ProtectedRoute element={<PropertyInsuranceRequest />} condition={checkCondition} />} />
                <Route path="/main-page/life-insurance" element={<ProtectedRoute element={<LifeInsuranceRequest />} condition={checkCondition} />} />
                <Route path="/main-page/confirmation" element={<ProtectedRoute element={<ConfirmationPage />} condition={checkCondition} />} />
                <Route path="/main-page/view/kasko" element={<ProtectedRoute element={<KaskoResponseView />} condition={checkCondition} />} />
                <Route path="/main-page/view/property" element={<ProtectedRoute element={<PropertyResponseView />} condition={checkCondition} />} />
                <Route path="/main-admin-page/choose" element={<ProtectedRoute element={<AdminResponseChooseView />} condition={checkAdminCondition} />} />
                <Route path="/main-page/choose" element={<ProtectedRoute element={<ClientResponseChooseView />} condition={checkCondition} />} />
                <Route path="/account-update" element={<ProtectedRoute element={<AccountUpdatePage />} condition={checkCondition} />} />
                <Route path="/about-us" element={<ProtectedRoute element={<AboutUs />} condition={checkCondition} />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/forgot-password/set" element={<SetPassword />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} /></Routes>
        </Router>
    );
}

export default App;
