import React from "react";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import triglav from '../images/Triglav-web.jpg';
import premium from '../images/Premium-Logo-og-2023.png';
import adriatic from '../images/download.png';
import uniqa from '../images/uniqaaa.jpg';
import wiener from '../images/WIENER-Logo-e1508417609390.jpg';
import grawe from '../images/csm_GRAWE_LOGO_2f35c35f30.png';

const Propaganda = () => {
    return(

        <div className="efi-tsm-01">
            <div className="efi-tsm-01-container">
                <div className="w-layout-grid efi-tsm-01-grid">
                    <div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600c7-c72600c4" className="efi-tsm-01-logos-wrapper">
                        <div className="propaganda-container">
                            <div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600c9-c72600c4" className="efi-tsm-01-logo-inner">
                                <img src={triglav} loading="lazy" sizes="(max-width: 851px) 94vw, 800px" alt="" className="image" />
                            </div>
                            <div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600cb-c72600c4" className="efi-tsm-01-logo-inner">
                                <img src={premium} loading="lazy" sizes="(max-width: 1276px) 94vw, 1200px" alt="" className="image" />
                            </div>
                            <div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600cd-c72600c4" className="efi-tsm-01-logo-inner">
                                <img src={adriatic} loading="lazy" alt="" className="image" /></div>
                            <div>
                                <img src={grawe} loading="lazy"
                                     id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d1-c72600c4"
                                     sizes="(max-width: 991px) 94vw, 748px" alt=""/>
                            </div>
                            <div>
                                <img src={wiener} loading="lazy"
                                     id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d2-c72600c4"
                                     alt=""/>
                            </div>
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d3-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d4-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d5-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d6-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d7-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d8-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600d9-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                            {/*<div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600da-c72600c4" className="efi-tsm-01-logo-inner" />*/}
                        </div>
                    </div>
                    <div id="w-node-d4ad4b5d-d54e-abed-8e90-63e7c72600db-c72600c4" className="efi-tsm-01-logos-wrapper">
                        <h2 className="efi-h2-5">Naši partneri</h2>
                        <p className="efi-paragraph">Sarađujemo s mnogo renomiranih i pouzdanih osiguravajućih društava koja su prošla rigorozne provjere kvaliteta. Naši partneri čine našu mrežu respektabilnih kompanija koje su posvećene pružanju najboljih usluga osiguranja. To znači da kada izaberete našu agenciju, imate pristup širokom spektru opcija od strane provjerenih lidera u industriji, kako biste pronašli idealno rješenje za vaše potrebe. Vaša sigurnost je naš prioritet, i naši partneri su ključni dio tog obećanja.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Propaganda;
