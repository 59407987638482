import React, { useState } from 'react';
import '../static/MainPage.css'; // Correct relative path
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';

import Header from './HeaderUser'
import Footer from './FooterUser'
import Propaganda from './Propaganda'
import LearnMore from "./LearnMoreHeader";
function MainPage() {


    return (
        <div>
            <Header/>
            <LearnMore/>
            <div id="zapocni" className="brix---section">
                <div id="Zapocni" className="brix---container-default w-container">
                    <div className="brix---mg-bottom-48px">
                        <div data-w-id="5b43e274-aea0-906a-5397-dafda7657e38" className="brix---inner-container-600px---center">
                            <div className="brix---text-center">
                                <div className="brix---mg-bottom-12px">
                                    <div className="brix---color-neutral-800">
                                        <h2 className="brix---heading-h2-size">Izaberite opciju</h2>
                                    </div>
                                </div>
                                <div className="brix---color-neutral-600">
                                    <p className="brix---paragraph-default">Odaberite neku od pogodnosti koje Vam nudi naša platforma.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-grid brix---grid-2-columns---gap-row-72px">
                        <a id="w-node-_5b43e274-aea0-906a-5397-dafda7657e42-a7657e35" data-w-id="5b43e274-aea0-906a-5397-dafda7657e42" href="/main-page/choose"  className="brix---card-link-img-bg w-inline-block">
                            <div>
                                <div className="brix---mg-bottom-8px">
                                    <div className="brix---color-neutral-100">
                                        <h3 className="brix---heading-h3-size">Pregled pristiglih ponuda</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-32px">
                                    <div className="brix---color-neutral-300">
                                        <p className="brix---paragraph-default">Pregledajte pristigle ponude za tipove osiguranja za koje ste podnijeli zahtjeve</p>
                                    </div>
                                </div>
                                <div className="brix---link-wrapper">
                                    <div className="brix---color-neutral-100">
                                        <div className="brix---text-200-bold">Odaberi</div>
                                    </div><img src="../images/icon-rounded-arrow-elements-brix-templates.svg" alt="Icon Rounded Arrow White - BRIX Templates" className="brix---link-icon-right" />
                                </div>
                            </div><img src="../images/iStock-1691602874 (2).jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 571px"  className="brix---card-image" />
                            <div className="brix---bg-overlay-gradient" />
                        </a>
                        <a id="w-node-_5b43e274-aea0-906a-5397-dafda7657e53-a7657e35" data-w-id="5b43e274-aea0-906a-5397-dafda7657e53" href="/main-page/kasko" className="brix---card-link-img-bg w-inline-block">
                            <div>
                                <div className="brix---mg-bottom-8px">
                                    <div className="brix---color-neutral-100">
                                        <h3 className="brix---heading-h3-size">Zatraži ponudu za kasko osiguranje vozila</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-32px">
                                    <div className="brix---color-neutral-300">
                                        <p className="brix---paragraph-default">Zatražite ponudu za kasko osiguranje vozila u nekoliko klikova. Izaberite odgovarajuću ponudu za Vaš automobil</p>
                                    </div>
                                </div>
                                <div className="brix---link-wrapper">
                                    <div className="brix---color-neutral-100">
                                        <div className="brix---text-200-bold">Odaberi</div>
                                    </div><img src="../images/icon-rounded-arrow-elements-brix-templates.svg" alt="Icon Rounded Arrow White - BRIX Templates" className="brix---link-icon-right" />
                                </div>
                            </div><img src="../images/iStock-1388634244 (2).jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 571px" className="brix---card-image" />
                            <div className="brix---bg-overlay-gradient" />
                        </a>
                        <a id="w-node-_5b43e274-aea0-906a-5397-dafda7657e64-a7657e35" data-w-id="5b43e274-aea0-906a-5397-dafda7657e64" href="/main-page/property" className="brix---card-link-img-bg w-inline-block">
                            <div>
                                <div className="brix---mg-bottom-8px">
                                    <div className="brix---color-neutral-100">
                                        <h3 className="brix---heading-h3-size">Zatraži ponudu za osiguranje imovine!</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-32px">
                                    <div className="brix---color-neutral-300">
                                        <p className="brix---paragraph-default">Zatražite ponudu za osiguranje imovine - brzo, jednostavno i pristupačno, jer briga o sigurnosti ne poznaje odlaganje</p>
                                    </div>
                                </div>
                                <div className="brix---link-wrapper">
                                    <div className="brix---color-neutral-100">
                                        <div className="brix---text-200-bold">Odaberi</div>
                                    </div><img src="images/icon-rounded-arrow-elements-brix-templates.svg" alt="Icon Rounded Arrow White - BRIX Templates" className="brix---link-icon-right" />
                                </div>
                            </div><img src="../images/iStock-1409298953 (1).jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 571px" className="brix---card-image" />
                            <div className="brix---bg-overlay-gradient" />
                        </a>
                        <a id="w-node-_5b43e274-aea0-906a-5397-dafda7657e64-a7657e35" data-w-id="5b43e274-aea0-906a-5397-dafda7657e64" href="/main-page/life-insurance" className="brix---card-link-img-bg w-inline-block">
                            <div>
                                <div className="brix---mg-bottom-8px">
                                    <div className="brix---color-neutral-100">
                                        <h3 className="brix---heading-h3-size">Zatraži ponudu za osiguranje života!</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-32px">
                                    <div className="brix---color-neutral-300">
                                        <p className="brix---paragraph-default">Zatražite ponudu za osiguranje života - brzo, jednostavno i pristupačno, jer briga o sigurnosti ne poznaje odlaganje</p>
                                    </div>
                                </div>
                                <div className="brix---link-wrapper">
                                    <div className="brix---color-neutral-100">
                                        <div className="brix---text-200-bold">Odaberi</div>
                                    </div><img src="../images/icon-rounded-arrow-elements-brix-templates.svg" alt="Icon Rounded Arrow White - BRIX Templates" className="brix---link-icon-right" />
                                </div>
                            </div><img src="../images/iStock-1478932682 (1).jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 571px" className="brix---card-image" />
                            <div className="brix---bg-overlay-gradient" />
                        </a>
                        <a id="w-node-_5b43e274-aea0-906a-5397-dafda7657e75-a7657e35" data-w-id="5b43e274-aea0-906a-5397-dafda7657e75" href="/account-update" className="brix---card-link-img-bg w-inline-block">
                            <div>
                                <div className="brix---mg-bottom-8px">
                                    <div className="brix---color-neutral-100">
                                        <h3 className="brix---heading-h3-size">Nalog</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-32px">
                                    <div className="brix---color-neutral-300">
                                        <p className="brix---paragraph-default">Pristupite konfiguraciji i istoriji svog naloga</p>
                                    </div>
                                </div>
                                <div className="brix---link-wrapper">
                                    <div className="brix---color-neutral-100">
                                        <div className="brix---text-200-bold">Odaberi</div>
                                    </div><img src="images/icon-rounded-arrow-elements-brix-templates.svg" alt="Icon Rounded Arrow White - BRIX Templates" className="brix---link-icon-right" />
                                </div>
                            </div><img src="../images/iStock-1501565599 (1).jpg" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 94vw, (max-width: 991px) 46vw, (max-width: 1439px) 47vw, 571px"  className="brix---card-image" />
                            <div className="brix---bg-overlay-gradient" />
                        </a>
                    </div>
                </div>
            </div>
            <Propaganda/>
            <Footer/>
        </div>
    );
}
export default MainPage;
