import React from 'react';
import AccordionItem from './AccordionItem';

const accordionData = [
    {
        title: 'Ušteda Novca',
        content:
            'Naš zadatak je da Vam dostavimo najbolje ponude. Poređenjem više ponuda, donijećete najbolje informisanu odluku i time uštedjeti novac.',
    },
    {
        title: 'Ušteda Vremena',
        content:
            'Nema potrebe za čekanjem redova na šalteru i obavljanjem bezbroj poziva. Sve obavljate ovdje, na jednom mjestu.',
    },
    {
        title: 'Besplatna Usluga',
        content:
            'Naše usluge su potpuno besplatne, jer se mi ne naplaćujemo od korisnika nego od osiguravajućih društava.',
    },
    {
        title: 'Bez Skrivenih Uslova',
        content: 'Naše poslovanje je potpuno transparentno.',
    },
];

const Accordion = () => {
    return (
        <div>
            {accordionData.map((item, index) => (
                <AccordionItem key={index} title={item.title} content={item.content} />
            ))}
        </div>
    );
};

export default Accordion;
