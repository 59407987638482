import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ element, condition }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!condition()) {
            navigate(-1); // Go back to the previous page
        }
    }, [location, condition, navigate]);

    return element;
};

export default ProtectedRoute;
