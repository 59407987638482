import React, { useState,useEffect } from 'react';
import '../static/MainPage.css'; // Correct relative path
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import Header from './HeaderUser';
import Footer from './FooterUser';
import config from "../config";

function ConfirmationPage() {
    useEffect(() => {
        // Asynchronous function to handle email sending
        const sendEmail = async () => {
            // Retrieve id and JWT from local storage
            //localStorage.setItem('id', 1);
            const id = localStorage.getItem('id');
            const type = localStorage.getItem('type');
            const jwt = localStorage.getItem('token');

            // Make GET request to backend to send email
            if (id && jwt && type) {
                try {
                    const response = await fetch(`${config.BASE_URL}api/send-emails?id=${id}&type=${type}`, {
                        headers: {
                            Authorization: `Bearer ${jwt}`},
                    });

                    if (response.ok) {
                        const data = await response.json();
                        console.log('Email sent successfully:', data);
                    } else {
                        console.error('Error sending email:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error sending email:', error);
                }
            } else {
                console.error('ID or JWT not found in local storage');
            }
        };

        sendEmail();
    }, []);

    return (
        <div>
            <Header />
            <div>
                <div className="f-section-large-3 confirmation-container">
                    <div className="f-container-regular-3 confirmation-title-container">
                        <div className="f-margin-bottom-137">
                            <div className="f-title-wrapper-center-2">
                                <div className="f-margin-bottom-136">
                                    <div className="f-heading-detail-small-4">Osiguraj-se</div>
                                </div>
                                <h3 className="f-h3-heading-2">Čestitamo! Uspješno ste kreirali zahtjev. Bićete dalje obavješteni putem Vašeg e-maila</h3>
                            </div>
                        </div>
                        <a href="#" className="f-gallery-lightbox w-inline-block w-lightbox">
                            <div className="f-gallery-image-l" />
                        </a>
                    </div>
                    <a href="/main-page" className="button-4 w-button">Nazad na početnu</a>
                    <a href="/main-page/choose" className="button-5 w-button">Povratak na zahtjeve</a>
                </div>

            </div>

            <Footer />
        </div>
    );
}

export default ConfirmationPage;
