import React, { useEffect, useState } from 'react';
import '../static/MainPage.css'; // Ensure correct relative path for your CSS files
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import '../static/propertyrequestsview.css'; // Import the specific CSS file
import '../static/kaskorequestsview.css';
import config from '../config';
import Header from './HeaderAdmin';
import Footer from './Footer';
import LoadingSpinner from './LoadingSpinner';


function PropertyRequestsView() {
    const [groupedData, setGroupedData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [loading, setLoading] = useState(false);


    const [responseFormData, setResponseFormData] = useState({
        price: 0,
        policy: '',
        requestid: 0,
        pdfFile: null,
        insurance: '', // New field for insurance
    });

    const getAuthToken = () => {
        return localStorage.getItem('token');
    };

    const fetchData = async () => {
        try {
            const token = getAuthToken();
            setLoading(false);


            if (!token) {
                setLoading(false);
                alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                console.error('Auth error');
                return;
            }

            const response = await fetch(`${config.BASE_URL}api/policyreq/getallpropertyreq`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                console.error('Failed to fetch data');
                setLoading(false);
                alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
                return;
            }

            const result = await response.json();
            setLoading(false);
            const grouped = result.reduce((acc, card) => {
                const dateKey = card.date.split('T')[0];
                acc[dateKey] = acc[dateKey] || [];
                acc[dateKey].push(card);
                return acc;
            }, {});

            setGroupedData(grouped);
        } catch (error) {
            setLoading(false);
            alert("Doslo je do greske u sistemu, molimo pokusajte ponovo!")
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCreateResponse = async () => {
        const { price, policy, requestid, pdfFile,insurance } = responseFormData;

        // Validation checks
        if (!price || !policy || !requestid || !pdfFile || !insurance) {
            alert('Sva polja moraju biti popunjena!');
            return;
        }

        try {
            const token = getAuthToken();
            setLoading(true);


            if (!token) {
                console.error('JWT not found in localStorage');
                setLoading(false);
                return;
            }

            const formData = new FormData();
            formData.append('price', responseFormData.price);
            formData.append('policy', responseFormData.policy);
            formData.append('requestid', responseFormData.requestid);
            formData.append('pdfFile', responseFormData.pdfFile);
            formData.append('insurance', responseFormData.insurance);

            const response = await fetch(`${config.BASE_URL}api/policyres/create-property-request`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                setLoading(false);
                alert("Doslo je do greske, molimo pokusajte ponovo!")
                console.error(`Error creating response: ${response.statusText}`);
                return;
            }

            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const result1 = await response.json();
            } else {
                const result1 = await response.text();
                setLoading(false);
                if (result1 === 'OK') {
                    alert('Polisa je uspješno kreirana i poslana kupcu!');
                }
                if (result1 === 'EMPTY') {
                    alert('Došlo je do problema!');
                }
                if (result1 === 'ERROR') {
                    alert('Došlo je do većeg problema!');
                }
                handleCloseModal();
            }
        } catch (error) {
            setLoading(false);
            alert('Došlo je do greške u sistemu');
            console.error('Error creating response:', error);
            handleCloseModal();
        }
    };

    const handlePdfFileChange = (event) => {
        const file = event.target.files[0];
        setResponseFormData((prevFormData) => ({
            ...prevFormData,
            pdfFile: file,
        }));
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCardClick = (card) => {
        setSelectedCard(card);
        setShowModal(true);

        setResponseFormData((prevFormData) => ({
            ...prevFormData,
            requestid: card.id,
        }));
    };

    const renderAdditionalDetails = (card) => {
        return (
            <>
                <p>Ime vlasnika: {card.ownerfirstname}</p>
                <p>Prezime vlasnika: {card.ownerlastname}</p>
                <p>Ime osiguranika: {card.insuredfirstname}</p>
                <p>Prezime osiguranika: {card.insuredlastname}</p>
                <p>Godina: {card.year}</p>
                <p>Veličina: {card.size}</p>
                <p>Adresa: {card.address}</p>
                <p>Procjenjena vrijednost po metru kvadratnom: {card.sum}</p>
                <p>Dodatna osiguranja: {card.additionals}</p>
                <p>Da li se osiguravaju stvari u objektu: {card.additionals === 'yes' ? 'Da' : 'Ne'}</p>
                <p>Spratnost: {card.storey}</p>
                <p>Broj mokrih čvorova: {card.numofwc}</p>
                <p>Dodatni komentar: {card.description}</p>
                <p>Tip: {card.type}</p>
                <p>Dodatni opis: {card.description}</p>
                <button className="button-8 w-button" onClick={() => handleCardClick(card)}>Kreiraj odgovor</button>
            </>
        );
    };

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    return (
        <div>
            <Header />
            <div className="lynx-features-container">
                {Object.keys(groupedData)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map((date) => (
                        <div className="lynx-date-group" key={date}>
                            <h1 className='heading-4'>{formatDate(date)}</h1>
                            <div className="lynx-cards-background">
                                <div className="lynx-cards-container">
                                    {groupedData[date].slice().reverse().map((card) => (
                                        <div className={`lynx-card ${groupedData[date].length === 1 ? 'lynx-card-full-width' : ''}`} key={card.id}>
                                            <div className="lynx-card-content">
                                                {renderAdditionalDetails(card)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            {showModal && selectedCard && (
                <div className="kasko-modal-overlay">
                    <div className="kasko-modal-content">
                        {/* Display selected card data in the modal */}
                        <p>Datum: {formatDate(selectedCard.date)}</p>
                        {/* ... Include other fields here ... */}
                        <p>Unesite cijenu</p>
                        <input
                            type="number"
                            placeholder="Cijena"
                            value={responseFormData.price}
                            onChange={(e) =>
                                setResponseFormData((prev) => ({ ...prev, price: e.target.value }))
                            }
                        />
                        <p>Unesite broj polise</p>
                        <input
                            type="text"
                            placeholder="Broj polise"
                            value={responseFormData.policy}
                            onChange={(e) =>
                                setResponseFormData((prev) => ({ ...prev, policy: e.target.value }))
                            }
                        />
                        <p>Izaberite osiguravajuću kuću</p>
                        <select
                            value={responseFormData.insurance}
                            onChange={(e) =>
                                setResponseFormData((prev) => ({ ...prev, insurance: e.target.value }))
                            }
                        >
                            <option value="">Izaberite...</option>
                            <option value="GRAWE">GRAWE</option>
                            <option value="PREMIUM">PREMIUM</option>
                            <option value="WIENER">WIENER</option>
                            <option value="TRIGLAV">TRIGLAV</option>
                            <option value="ADRIATIC">ADRIATIC</option>
                        </select>
                        <p>Priložite fajl sa polisom</p>
                        <input type="file" onChange={handlePdfFileChange} />
                        <button onClick={() => handleCreateResponse()}>Pošalji polisu</button>
                        <button onClick={() => handleCloseModal()}>Zatvori</button>
                    </div>
                </div>
            )}
            <LoadingSpinner loading={loading} />
            <Footer />
        </div>
    );
}

export default PropertyRequestsView;
