import React from "react";
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';




const LearnMore = () => {
    return (
<div className="f-section-large-2">
    <div data-w-id="697f890c-704d-75ec-90e6-e16ba52176a1" className="f-container-regular-2 learn-more-header">
        <div id="w-node-_697f890c-704d-75ec-90e6-e16ba52176a2-a52176a0" data-w-id="697f890c-704d-75ec-90e6-e16ba52176a2" className="f-header-wrapper-left learn-more-text-container">
            <div className="f-margin-bottom-131">
                <div className="f-heading-detail-small-3">Osiguraj-Se - Vaša Vizija Budućnosti</div>
            </div>
            <div className="f-margin-bottom-130">
                <h1 className="f-h1-heading-2">Uštedi vrijeme i novac i zatraži ponudu za polisu osiguranja</h1>
            </div>
            <div className="f-margin-bottom-129">
                <p className="f-paragraph-large-2">Osiguraj-Se nudi brzu i jednostavnu proceduru, omogućavajući korisnicima da zatraže ponudu od više osiguravajućih kuća, po najpovoljnim cijenama.</p>
            </div>
            <div className="f-header-button-wrapper">
                <a href="#Zapocni" className="f-button-neutral-4 w-inline-block">
                    <div>Započni</div>
                </a>
                <a href="/about-us" className="f-button-secondary-2 w-inline-block">
                    <div>Saznaj više</div>
                </a>
            </div>
        </div>
    </div>
</div>);
}
export default LearnMore;
