import React, { useState } from 'react';
import '../static/MainPage.css'; // Correct relative path
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import Header from './HeaderUser'
import Footer from './FooterUser'

function KaskoFirstPage(){
    return(
        <div>
            <Header/>
            <div className="brix---section-2">
                <div className="brix---container-default-2 w-container">
                    <div data-w-id="5b43e274-aea0-906a-5397-dafda7657e38" className={"kasko-title-container"}>
                        <div className="brix---text-center">
                            <div className="brix---color-neutral-800">
                                <h1 className="brix---heading-h1-size">Za koju vrstu vozila želite da kreirate zahtjev za kasko osiguranje?</h1>
                            </div>
                            <div className="brix---mg-bottom-40px">
                                <div className="brix---color-neutral-801">
                                    <p className="brix---paragraph-default-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="f13b8209-d387-c54f-6ac0-793624eb80a2"  className="w-layout-grid brix---grid-3-columns">
                        <a id="w-node-f13b8209-d387-c54f-6ac0-793624eb80a3-d632206e" data-w-id="f13b8209-d387-c54f-6ac0-793624eb80a3" href="/main-page/kasko/car" className="brix---product-card-wrapper-v1 w-inline-block">
                            <div className="brix---product-card-image-inside-card"><img height={248}  sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 45vw, (max-width: 1439px) 30vw, 369.7250061035156px" src="../images/6558a2f57fdef2ebe46d21fe_Chrysler-Airflow-concept.webp.jpg"  className="brix---product-card-image" />
                                <div className="brix---badge-wrapper-top-right" />
                            </div>
                            <div className="brix---product-card-content kasko-card-body">
                                <div className="brix---mg-bottom-16px">
                                    <div className="brix---color-neutral-802">
                                        <h3 className="brix---heading-h4-size">Automobil</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-24px">
                                    <div className="brix---color-neutral-801">
                                        <p className="brix---paragraph-default-2">Motorno vozilo namijenjeno prevozu putnika koje se koristi u privatne svrhe, često obuhvaćeno osiguranjem &nbsp;radi zaštite od potencijalnih šteta i nezgoda.</p>
                                    </div>
                                </div>
                                <div className="brix---btn-primary">Odaberi</div>
                            </div>
                        </a>
                        <a data-w-id="f13b8209-d387-c54f-6ac0-793624eb80b4" href="/main-page/kasko/motorcycle" className="brix---product-card-wrapper-v1 w-inline-block">
                            <div className="brix---product-card-image-inside-card"><img src="../images/iStock-1681431415.jpg" className="brix---product-card-image" height={248}/>
                                <div className="brix---badge-wrapper-top-right" />
                            </div>
                            <div className="brix---product-card-content kasko-card-body">
                                <div className="brix---mg-bottom-16px">
                                    <div className="brix---color-neutral-802">
                                        <h3 className="brix---heading-h4-size">Motocikl</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-24px">
                                    <div className="brix---color-neutral-801">
                                        <p className="brix---paragraph-default-2">Motocikl je dvotočkaško motorno vozilo namijenjeno prevozu jednog ili više putnika, koje se često osigurava radi pokrića odgovornosti vozača i zaštite od eventualnih šteta i nesreća.<br /></p>
                                    </div>
                                </div>
                                <div className="brix---btn-primary">Odaberi</div>
                            </div>
                        </a>
                        <a data-w-id="f13b8209-d387-c54f-6ac0-793624eb80c5" href="/main-page/kasko/truck" className="brix---product-card-wrapper-v1 w-inline-block">
                            <div className="brix---product-card-image-inside-card"><img src="../images/iStock-1380261731.jpg" className="brix---product-card-image" height={248}/>
                                <div className="brix---badge-wrapper-top-right" />
                            </div>
                            <div className="brix---product-card-content kasko-card-body">
                                <div className="brix---mg-bottom-16px">
                                    <div className="brix---color-neutral-802">
                                        <h3 className="brix---heading-h4-size">Teretno Vozilo</h3>
                                    </div>
                                </div>
                                <div className="brix---mg-bottom-24px">
                                    <div className="brix---color-neutral-801">
                                        <p className="brix---paragraph-default-2">Teretno vozilo je motorno vozilo dizajnirano za prevoz robe ili tereta, često obuhvaćeno osiguranjem koje uključuje pokriće odgovornosti vozača kako bi se zaštitilo od mogućih šteta.</p>
                                    </div>
                                </div>
                                <div className="brix---btn-primary">Odaberi</div>
                            </div>
                        </a>
                        <a data-w-id="f13b8209-d387-c54f-6ac0-793624eb80d6" href="#" className="brix---product-card-wrapper-v1 w-inline-block">
                            <div className="brix---product-card-image-inside-card">
                                <div className="brix---badge-wrapper-top-right">
                                    <div className="brix---badge-secondary-small-bold">$149.00</div>
                                </div>
                            </div>
                        </a>
                        <a data-w-id="f13b8209-d387-c54f-6ac0-793624eb80e7" href="#" className="brix---product-card-wrapper-v1 w-inline-block">
                            <div className="brix---product-card-image-inside-card">
                                <div className="brix---badge-wrapper-top-right">
                                    <div className="brix---badge-secondary-small-bold">$19.00</div>
                                </div>
                            </div>
                        </a>
                        <a data-w-id="f13b8209-d387-c54f-6ac0-793624eb80f8" href="#" className="brix---product-card-wrapper-v1 w-inline-block">
                            <div className="brix---product-card-image-inside-card">
                                <div className="brix---badge-wrapper-top-right">
                                    <div className="brix---badge-secondary-small-bold">$49.00</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}
export default KaskoFirstPage;
