import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import '../static/login.css';
import '../static/normalize.css';
import '../static/webflow.css';
import '../static/osiguranje.webflow.css';
import image from '../images/iStock-1442447917 (3).jpg';
import config from '../config';
import Propaganda from "./Propaganda";
import LoadingSpinner from './LoadingSpinner';


function RegistrationPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [fieldError, setFieldError] = useState('');
    const [loading, setLoading] = useState(false);


    const handleEmailChange = (event) => setEmail(event.target.value);
    const handleFirstnameChange = (event) => setFirstname(event.target.value);
    const handleLastnameChange = (event) => setLastname(event.target.value);

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!#%&]{8,}$/;
        if (newPassword.length < 8 || !passwordRegex.test(newPassword)) {
            setPasswordStrength('Lozinka mora sadržavati najmanje 8 karaktera, uključujući barem jedan broj.');
        } else {
            setPasswordStrength('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
        validatePassword(password, newConfirmPassword);
    };

    const validatePassword = (newPassword, newConfirmPassword) => {
        if (newPassword !== newConfirmPassword) {
            setPasswordError('Lozinke se ne podudaraju.');
        } else {
            setPasswordError('');
        }
    };

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const handleRegistration = (event) => {
        event.preventDefault();
        setLoading(true);
        setLoginError(false);
        setTermsError(false);
        setFieldError('');

        if (!email || !firstname || !lastname || !password || !confirmPassword) {
            setLoading(false);
            setFieldError('Sva polja moraju biti popunjena.');
            return;
        }

        if (!isChecked) {
            setTermsError(true);
            setLoading(false);
            return;
        }
        setLoading(true);
        if (passwordError === '' && passwordStrength === '' && isChecked) {
            setLoading(true);
            axios.post(`${config.BASE_URL}api/auth/register`, {
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                role: "USER",
                insurancehouse: "-"
            })
                .then(response => {
                    localStorage.setItem("token", response.data.token);
                    setLoading(false);
                    navigate('/main-page');
                })
                .catch(error => {
                    setLoginError(true);
                    setLoading(false);
                    console.error('Registration failed:', error);
                });
        }
        setLoading(false);
    };

    return (
        <div>
            <Header />
            <div className="f-account-section-2">
                <div className="f-account-image-wrapper-2">
                    <img src={image} loading="lazy" sizes="(max-width: 767px) 100vw, 45vw" alt="" className="f-image-cover-3" />
                </div>
                <div className="f-account-container-r-3">
                    <div className="f-account-content-wrapper-2">
                        <div className="f-margin-bottom-27">
                            <h5 className="f-h5-heading-2">Registracija</h5>
                        </div>
                        <div className="f-account-form-block-2 w-form">
                            <form onSubmit={handleRegistration}>
                                <div className="w-layout-grid f-account-input-grid-2">
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Ime</div>
                                        <input type="text" className="f-field-input-2 w-input" maxLength={256} placeholder="Vaše ime..." id="firstname" value={firstname} onChange={handleFirstnameChange} />
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Prezime</div>
                                        <input type="text" className="f-field-input-2 w-input" maxLength={256} placeholder="Vaše prezime..." id="lastname" value={lastname} onChange={handleLastnameChange} />
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">E-mail</div>
                                        <input type="email" className="f-field-input-2 w-input" maxLength={256} placeholder="Vaš e-mail..." id="username" value={email} onChange={handleEmailChange} />
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Lozinka</div>
                                        <input type="password" className="f-field-input-2 w-input" maxLength={256} placeholder="Unesite lozinku..." id="password" value={password} onChange={handlePasswordChange} />
                                        {password && <div className={`password-strength ${passwordStrength}`}>{passwordStrength}</div>}
                                    </div>
                                    <div className="f-field-wrapper-2">
                                        <div className="f-field-label-2">Ponovite lozinku</div>
                                        <input type="password" className="f-field-input-2 w-input" maxLength={256} placeholder="Potvrdite lozinku..." id="confirmPassword" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                    </div>
                                    {passwordError && <div className="password-error">{passwordError}</div>}
                                    <div>
                                        <div className={`custom-checkbox ${isChecked ? 'checked' : ''}`} onClick={handleCheckboxClick}></div>
                                        <label>Prihvatam uslove korištenja</label>
                                    </div>
                                    <div className={`w-form-fail ${termsError ? 'show' : 'hide'}`}>
                                        <div>Morate prihvatiti uslove korištenja.</div>
                                    </div>
                                    <div className={`w-form-fail ${fieldError ? 'show' : 'hide'}`}>
                                        <div>{fieldError}</div>
                                    </div>
                                </div>
                                <div className="f-account-form-button-2">
                                    <input
                                        type="submit"
                                        value="Registracija"
                                        className={`f-button-neutral-2 w-button ${passwordError !== '' || passwordStrength !== '' || !isChecked ? 'disabled' : ''}`}
                                        //disabled={passwordError !== '' || passwordStrength !== '' || !isChecked}
                                    />

                                </div>
                            </form>
                            <LoadingSpinner loading={loading} />
                            <div className="f-success-message-2 w-form-done">
                                <div>Hvala! Vaša registracija je uspješna!</div>
                            </div>
                            <div className={`w-form-fail ${loginError ? 'show' : 'hide'}`}>
                                <div>Prilikom kreiranja naloga desila se greška, molimo pokušajte opet, ukoliko se greška ponovi pokušajte kasnije!</div>
                            </div>
                        </div>
                        <p className="f-paragraph-small-2">Već imate nalog? <a href="/login" className="f-account-link-2">Prijavite se</a></p>
                    </div>
                </div>
            </div>
            <Propaganda/>
            <Footer />
        </div>
    );
}

export default RegistrationPage;
