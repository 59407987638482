import React, { useState } from 'react';

const AccordionItem = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="efi-ft-09-accordion-wrap">
            <div className="efi-ft-09-bar w-inline-block" onClick={toggleAccordion}>
                <h4 className="efi-h4">{title}</h4>
                <img
                    src="../images/plus.svg"
                    width="24"
                    height="24"
                    alt=""
                    className="efi-plus-icon"
                />
            </div>
            <div
                className="efi-ft-09-content"
                style={{ height: isOpen ? 'auto' : '0', overflow: 'hidden' }}
            >
                <p className="efi-accordion-paragraph">{content}</p>
                <div className="efi-ft-09-accordion-bottom-bar" />
            </div>
        </div>
    );
};

export default AccordionItem;
